<template>
    <div>
        <v-row  align="center" :justify="campaignOptions && campaignOptions.amount !== 'HIDE' ? 'space-between' : 'center'" class="px-5">
            <template v-if="loading">
                <v-skeleton-loader class="progress-loading w-full"
                    type="card-heading"
                ></v-skeleton-loader>
            </template>
            <template  v-if="activeCampaignModel && !loading" >
                <v-col cols="12" :class="campaignOptions && campaignOptions.amount !== 'HIDE' ? 'col-lg-10 col-md-10 py-0' : 'py-0'">
                    <div ref="chartElement" class="w-full" style="height: 200px"></div>
                </v-col>
                <v-col cols="12" class="col-lg-2 col-md-2 d-flex flex-wrap align-center justify-center d-lg-block d-md-block py-0 text-center" v-if="campaignOptions && campaignOptions.amount !== 'HIDE'">
                    <h2 class="mr-4 mr-lg-0 mr-md-0">{{ campaignOptions.amount === 'DOLLAR' ? `$ ${$helpers.currencyFormat(activeCampaignModel.remaining, false)}` : `${activeCampaignModel.progress} %`}} </h2>
                    <h5> {{ campaignOptions.amount === 'DOLLAR' ? 'to go' : 'campaign progress'}}</h5>
                </v-col>
            </template>
        </v-row>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import * as am5 from '@amcharts/amcharts5';
    import * as am5xy from '@amcharts/amcharts5/xy';
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

    export default {
        data: (vm) => ({
            chart: '',
            loading: false,
            activeCampaignModel: null,
            campaignOptions: null,
            model:[]
        }),
        created() {
            this.getCampaignOption();
        },
        methods: {
            ...mapActions(['getActiveCampaign', 'getOptionsData']),

            getCampaignOption() {
                this.loading = true;
                this.getOptionsData('CAMPAIGN_PROGRESS').then((response) => {
                    this.campaignOptions = response.option_value;
                    this.getCampaignModel();
                }).catch(err => this.loading = false);
            },
            getCampaignModel() {
                this.getActiveCampaign({with_variation: 1}).then((resp) => {
                    this.activeCampaignModel = resp;
                    if(resp && resp.progress) {
                        this.model = [{
                            name: 'chart',
                            value: resp.progress,
                            min: 0,
                            max: 100,
                            pictureSettings: {
                                src: this.campaignOptions.user_image ||`${window.location.origin}/user-image.png`
                            }
                        }];
                        setTimeout(() => {
                            this.chartInitiated();
                        });
                    }
                    this.loading = false;
                }).catch(err => this.loading = false);
            },

            async chartInitiated() {
                // Create chart instance
                let root = am5.Root.new(this.$refs.chartElement);

                //Remove Amchart Logo From Leftside Bottom
                root._logo.dispose();

                // Themes begin
                root.setThemes([am5themes_Animated.new(root)]);

                // Create container
                let chart = root.container.children.push(am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    wheelX: "none",
                    wheelY: "none",
                    layout: root.verticalLayout,
                    paddingLeft: 40,
                    paddingRight: 40
                }));

                // Create axes
                let yAxisRender = am5xy.AxisRendererY.new(root, {
                        inversed: true,
                        cellStartLocation: 0.1,
                        cellEndLocation: 0.9
                    });
                yAxisRender.grid.template.set("visible", false);
                const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
                    categoryField: "name",
                    visible: false,
                    renderer: yAxisRender,
                }));

                let xAxisRender = am5xy.AxisRendererX.new(root, {});
                xAxisRender.grid.template.set("strokeDasharray", [3]);
                const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
                    renderer: xAxisRender,
                    min: 0,
                    max: this.model[0].max
                }));

                const chartData = {
                    axes : { xAxis: xAxis, yAxis: yAxis},
                    chart: chart,
                    data: this.model
                }

                // Add cursor
                let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
                cursor.lineX.set("visible", false);
                cursor.lineY.set("visible", false);

                // Add series
                await this.createSeries(root, chartData, true);
                await this.createSeries(root, chartData);

                // Make stuff animate on load
                chart.appear(1000, 100);

                this.chart = chart;
            },
            createSeries(root, chartData, range=false) {
                let series = chartData.chart.series.push(
                    am5xy.ColumnSeries.new(root, {
                        name: "Campaign",
                        ...chartData.axes,
                        valueXField: range ? "max" : "value",
                        categoryYField: "name",
                        openValueYField: range ? "value" : "min",
                        sequencedInterpolation: true,
                        calculateAggregates: true,
                        clustered: false,
                        maskBullets: false,
                        tooltip: range? false : am5.Tooltip.new(root, range ? {} : {
                            dy: -30,
                            pointerOrientation: "vertical",
                                labelText: "{valueX} %"
                        }),
                    })
                );
                series.columns.template.setAll({
                    strokeOpacity: 0,
                    cornerRadiusBR: 10,
                    cornerRadiusTR: 10,
                    cornerRadiusBL: 10,
                    cornerRadiusTL: 10,
                    maxHeight: 50,
                });

                if(range) {
                    series.columns.template.set("fill", "#ECECEC")
                } else {
                    let container = am5.Container.new(root, {});

                    container.children.push(am5.Picture.new(root, {
                        templateField: "pictureSettings",
                        width: 60,
                        height: 60,
                        centerX: am5.p50,
                        centerY: am5.p50,
                        shadowColor: am5.color(0x000000),
                    }));
                    const cirleCorner = {radius: 30};
                    if(this.campaignOptions.circle_corner) {
                        cirleCorner['fill'] = am5.color(this.campaignOptions.progress_color.hex || 0xe5dc36);
                        cirleCorner['stroke'] = am5.color(this.campaignOptions.progress_color.hex || 0xe5dc36);
                        cirleCorner['strokeWidth'] = 3;
                    }
                    let circle = container.children.push(am5.Circle.new(root, cirleCorner));

                    container.set("mask", circle);

                    series.bullets.push(function() {
                        return am5.Bullet.new(root, {
                            locationX: 1,
                            sprite: container
                        });
                    });
                    series.columns.template.set('fill', am5.color(this.campaignOptions.progress_color.hex || 0xe5dc36))
                    series.columns.template.set("fillGradient", am5.LinearGradient.new(root, {
                        stops: [{
                          brighten: 1
                        },{
                          brighten: -0.2
                        }],
                        rotation: 0
                    }));
                }

                series.data.setAll(chartData.data);
                chartData.axes.yAxis.data.setAll(chartData.data);
                series.appear();

                return series;
            }
        },
        beforeDestroy() {
            if (this.chart) {
                this.chart.dispose();
            }
        }
    }
</script>